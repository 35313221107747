import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const Privacy = () => (
  <React.Fragment>
    <Helmet>
      <title>Privacy | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Privacy | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Privacy policy for birk products and services. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Privacy policy for birk products and services. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="http://birk.itechnotion.com/privacy-policy"
      />
      <link rel="canonical" href="http://birk.itechnotion.com/privacy-policy" />
    </Helmet>
    <Header />
    <div className="privacy-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="heading">
            <h2>Privacy Policy</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            <div className="privacy-content mb-3">
              <h6 className="mb-3">
                When we say "we," "us," "our" or "birk.com.au" we are referring
                to birk.com.au. Our goal is to promote window furnishings by
                birk and provide a platform to facilitate the purchase of window
                furnishings. We do this by building, maintaining and promoting
                the platform for you, by whom we mean Users/Visitors in the form
                of clients looking for bespoke window furnishings. The running
                of this platform is what we will refer to as “Service”.
              </h6>

              <h5>INTRODUCTION</h5>
              <p>
                This Privacy Notice sets out the types of personal data that we
                collect about you, how and why we use it, how long we will keep
                it for, when why and who we will share it will, the legal basis
                for us using your personal data and your rights in relation to
                us storing and processing your personal data. It also explains
                how we may contact you and how you can contact us.
              </p>
              <p>
                We ensure that the data you supply to us is processed fairly and
                lawfully, and with skill and care. By registering your details
                with us and using our website, you consent to us collecting and
                processing personal data supplied by you. The birk.com.au
                platform collections personal information such as full name,
                email addresses and other contact details. When we say "Personal
                information" we mean information that either alone or in
                combination with other data can identify you as an individual.
              </p>
              <p>
                This Privacy Notice provides you with clear information as to
                how we process your personal data. We are obliged to use your
                information in line with all applicable laws. We do so in a
                manner that is fair by using your information in a way that you
                would reasonably expect when providing our services, and being
                transparent so that you know how it will be used.
              </p>

              <h5>REGISTRATION</h5>
              <p>
                We do not require registration in order to browse on our site.
                You may choose to give us your contact information during
                registration or at other times. We then may use that information
                to contact you about the products and services on our site for
                which you have expressed interest. Registration is required to
                use certain interactive features such purchasing window
                furnishings.
              </p>

              <h5>INFORMATION COLLECTED FROM CLIENTS</h5>
              <p>
                We may store information on your behalf, this includes
                information like postal address, phone number and email address.
                We may receive, process and store information about how you
                interact with our platform and the ways you use our Services. In
                order to help us improve our Services for you and our other
                Users, we may link it to other information we collect about you.
                This information may include, for example, your IP address,
                time, date, browser used, and actions you have taken within the
                application.
              </p>
              <p>
                We may also send e-mails about our site or respond to inquiries,
                of which you as a Client can opt out at any time, feel free to
                contact us about this at support@birk.com.au. If you would like
                your data deleted from our system for any reason, please email
                support@birk.com.au.
              </p>

              <h5>COOKIES AND TRACKING</h5>
              <p>
                birk.com.au and our partner services such as Google Analytics
                may collect certain information during your time on our platform
                via "cookies" or similar technologies. These cookies have the
                important function to deliver us information we use to better
                our Services by delivering us valuable data on, for example,
                demographics, count of visitors to our site and places within
                the site that are popular. Most web browsers tend to accept
                these cookies by default, but if you would like to not be
                tracked by these you can switch them off via your browser
                settings. If you choose to remove the tracking function of these
                cookies it will affect certain features and Services on our
                site.
              </p>
              {/* <ul className="ml-4">
                <li>
                  To improve customer service.
                  <br />
                  Information you provide helps us respond to your customer
                  service requests and support needs more efficiently.
                </li>
                <li>
                  To personalise user experience.
                  <br />
                  We may use information in the aggregate to understand how our
                  Users as a group use the services and resources provided on
                  our Site.
                </li>
                <li>
                  To improve our Site.
                  <br />
                  We may use feedback you provide to improve our products and
                  services.
                </li>
                <li>
                  To process payments.
                  <br />
                  We may use the information Users provide about themselves when
                  placing an order only to provide service to that order. We do
                  not share this information with outside parties except to the
                  extent necessary to provide the service.
                </li>
                <li>
                  To send periodic emails.
                  <br />
                  We may use the email address to send User information and
                  updates pertaining to their order. It may also be used to
                  respond to their inquiries, questions, and/or other requests.
                </li>
              </ul> */}

              <h5>USE OF YOUR PERSONAL INFORMATION</h5>
              <p>
                We use your personal information to fulfil your requests for
                products and services, to improve our services, to customize the
                advertising and content you see, and to contact you (e.g. To
                send you important information such as confirmations, invoices,
                technical notices, updates, security alerts and support and
                administrative messages).
              </p>
              <p>
                Your reviews, comments and any other information you make
                available in public areas of our website likely will be seen by
                people and organizations not related to or controlled by
                birk.com.au.
              </p>
              <p>Will we contact you for marketing purposes?</p>
              <p>
                We will only send you direct marketing emails that promote our
                company or services if you have opted-in to this. You will have
                the option to opt-in when you are provided with a copy of our
                Privacy Notice. You can also manage your direct marketing
                preferences by contacting support@birk.com.au.
              </p>

              <h5>THE RELEASE OF YOUR INFORMATION</h5>
              <p>
                birk.com.au will never sell your personal information to third
                parties.
              </p>
              <p>
                Because of the nature of our business, we may hire third party
                individuals and/or organizations to help improve our services.
                When we say third party individuals and/or organizations we mean
                for example our web host provider, other SaaS providers, such as
                an email hosting service, payment processors or outside
                contractors that we hire to perform maintenance or assist us in
                securing our website.
              </p>
              <p>
                If required to do so, we also may share personal information
                when we believe it necessary to investigate, prevent, or take
                action regarding illegal activities, suspected fraud, situations
                involving potential threats to the physical safety of any
                person, violations of our Terms of Service, or as otherwise
                required by law.
              </p>

              <h5>THIRD PARTY WEBSITES</h5>
              <p>
                birk.com.au also has public profiles across a range of social
                media sites. Any information that you include in a comment on
                any of our posts online may be read, collected, and used by
                anyone else. If your Personal Information appears on our social
                media and you want it removed, contact us at support@birk.com.au
                we will get back to you as soon as we can.
              </p>

              <h5>INFORMATION SECURITY</h5>
              <p>
                We invest a lot of effort in maintaining commercially reasonable
                administrative, technical, and physical safeguards that protect
                your Personal Information in order to prevent loss, misuse, and
                unauthorized access, disclosure, alteration and destruction of
                your data. These measures include for example using SSL
                Certificates. However, this is the Internet we’re talking about
                and no transmission is 100% secure thus we cannot promise that
                the information about you will remain secure in all
                circumstances. It is also your responsibility to protect your
                data to help prevent anyone with ill intent to access and abuse
                your accounts and Services. One of the easiest ways of doing so
                is to not reuse the same passwords you use with other accounts
                as your password for our Services.
              </p>

              <h5>NOTICE OF BREACH OF SECURITY</h5>
              <p>
                If we ever do notice an unauthorized security breach into our
                system that affects you in ways such as the loss of your data or
                the theft of it we will notify you as soon as possible as well
                as the responsible authorities and report on the actions we took
                in response.
              </p>
              <h5>CHILDREN</h5>
              <p>
                We do not intentionally gather Personal Information of visitors
                on our site that are under the age of 16. If you are under the
                age of 16 you are not permitted to submit any Personal
                Information to us. If we learn that a child under 16 submits
                Personal Information or if you believe that we might have stored
                Personal Information on a child under 16 please contact us at
                support@birk.com.au and we will endeavour to delete the
                information.
              </p>
              <h5>YOUR INFORMATION</h5>
              <p>
                Whenever you are using our site and its features, we want to
                make sure that the Personal Information you deliver to us is
                correct. If that information is ever wrong, we want to give you
                the option to correct, update, pause or delete it, that is
                unless we have to keep this data due to a legitimate business or
                legal purpose. If you ever do want to make changes or update
                your Personal Information, we may ask you to verify that it is
                really you via personal identification.
              </p>
              <p>
                We may reject certain unreasonable types of requests that are
                for example repetitive spams, require disproportionate technical
                effort (for example, developing a new system or fundamentally
                changing an existing practice), those that risk the privacy of
                others, or would be extremely impractical. Whenever we can
                provide information access and or correction for you, we will do
                so for free, always. Because we put such care into protecting
                information from accidental or ill-intended destruction, even
                after the information is deleted from the platform, it may not
                be immediately deleted from our servers. To address any sort of
                request that you have on the Personal Information from our site,
                Services, or social media, contact us at support@birk.com.au.
              </p>
              <p>Changes to this privacy notice</p>
              <p>
                We reserve the right to change our Privacy Notice at any time.
                Changes to our Privacy Notice will be displayed on our website.
              </p>

              <h5>Contacting us</h5>
              <p>
                If you have any questions about this Privacy Policy, the
                practices of this site, or your dealings with this site, please
                contact us at:
              </p>

              <address>
                Birk Solutions
                <br />
                http://birk.com.au/
                <br />
                2/55 Northgate Drive.
                <br />
                Thomastown, 3074
                <br />
                Melbourne, Australia
                <br />
                +61 3 9464 5444
                <br />
                admin@birk.com.au
              </address>
              <p>This document was last updated on 13th of November, 2013</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </React.Fragment>
);
export default Privacy;
